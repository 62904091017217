.summer{
    color:rgb(26, 15, 15);
    padding:10px 0px 0px 0px;
    margin-bottom: 2px;
    font-weight: bold;
    font-size: x-large;
    text-shadow: 2px 2px 5px rgb(63,63,69);
    /* padding: 0px 10px 20px 10px; */
}

.btn_ser_rate{
    background-color: rgb(215, 187, 187);
    text-align: left;
}
.card_outer_ser_rate{
    background-color: rgb(131, 188, 226);
    padding: 10px 2px 1px ;
    /* padding-top: 10px; */
}

.rate_title{
    /* background-color: black; */
    color: white;
    /* padding: 8px 0px 8px 0px; */
}
.types{
    font-size: x-large;
    color: blue;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
    
}
.title_choose_ser_rate{
    font-weight: bold;
    font-size: medium;
    color: black;
    
}

.thead-dark{
    background-color: rgb(141, 147, 146);
    color: rgb(51, 54, 53);
}
i{
    color: black;
}
.labur{
    color: green;
}

.table{
    background-color: white;
    color: black;
}