.active{
    background-color: rgb(90,128,181);
}

.container1{
    background-color: rgb(231,231,245);
    

    max-width: 100%;
}

nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(91,35,116);
    /* background-color: white; */
    position: sticky;
    top:0;
    z-index: 10;
}
.logo{
    
    margin: 5px 0px 5px 10px;
}
.image_name{
    display: flex;
    color: white;
    /* color:black; */
    font-weight: bold;
    align-items: center;
    justify-content: center;
    
}
.title_name{
    padding-left: 20px;
}
nav.title{
    font-size: 1.5rem;
    margin: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color:white;
    /* color: black; */
}

nav ul{
    display: flex;
}

nav ul li{
    list-style: none;
    margin-right: 2.5rem;
}



.link{
    display: block;
    text-decoration: none;
    color:white;
    /* color: black; */
    padding:0.5rem;
}

nav ul li a:hover{
    background-color: aquamarine;
}

.link:hover{
    background-color: aquamarine;
}

nav .menu{
    display:none;
    position:absolute;
    top:0.75rem;
    right: 0.75rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span{
    height:0.35rem;
    width:100%;
    background-color: white;
    /* background-color: black; */
    border-radius: 0.2rem;
}

@media(max-width:480px){
    nav .menu{
        display: flex;
    }

    nav{
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul{
        /* display: flex; */
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
        right: -300px;
    }


    nav ul.open{
        display: flex;
    }

    nav ul li{
        width: 100%;
        text-align: left;
    }

    nav ul li a{
        margin: 0.2rem 0.5rem;
    }

}