.bestAC{
    font-weight: bold;
    text-align: center;
    margin: 10px 10px 10px 10px;
    font-size: x-large;
    color: rgb(58,88,138);
}

.first_name{
    font-weight: bold;
    text-align: center;
    font-size: xx-large;
    color: rgb(25,23,53);
    text-shadow: 2px 2px 5px rgb(63,63,69);
    padding: 0px 10px 20px 10px;
}

.container{
    /* background-color: rgb(16,247,247); */
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
}
.containerImage{
    width: 98%;
    margin: 5px;
    
}
.image{
    margin: 10px 2px 10px 2px;
    object-fit: cover;
}

.col{
    padding-left: 1px;
    border-color: rgb(15,15,15);
}

.card-body{
    padding:1px;
}

.card-text,.card-title{
    white-space: nowrap;
    padding: 0px 0px 0px 0px;
    font-size: small;

}

.card-title{
    font-weight: bold;

    color:rgb(162,82,82);
    font-size: larger;
}